<template>
  <div
    class="tw-flex tw-w-full tw-h-screen tw-justify-center tw-items-center"
    style="
      background: linear-gradient(
        135.05deg,
        rgba(240, 247, 254, 0.69) 41.6%,
        #fef5f0 73.72%
      );
    "
  >
    <v-col sm="12" lg="7" class="tw-hidden left-side lg:tw-flex">
      <v-col sm="12" class="left-side-body">
        <img
          src="@/assets/logos/white.png"
          alt="logo"
          style="height: 54px; width: 186px; cursor: pointer"
          @click="$router.push({ name: 'HomePage' })"
        />
        <label class="buddy">Hey Buddy!</label>
        <h6 class="welcome tw-mt-5">Welcome to Nomadicpod.</h6>
      </v-col>
    </v-col>
    <v-col
      sm="12"
      lg="5"
      class="right-side tw-flex lg:tw-items-center tw-items-start"
    >
      <v-col sm="12" class="right-side-body">
        <img
          src="@/assets/logos/blue.png"
          alt="logo"
          style="height: 34px; width: 136px"
          class="mt-6 tw-cursor-pointer"
          @click="$router.push({ name: 'HomePage' })"
        />
        <h6 class="welcome tw-mt-0">Welcome Back!</h6>

        <h6 class="signup-header mb-1 mb-md-5">New Password</h6>

        <form @submit.prevent="changePassword">
          <ValidationProvider
            name="Password"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="tw-pt-4 mb-2" :class="classes">
              <v-text-field
                hide-details
                :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (value = !value)"
                :type="value ? 'text' : 'password'"
                solo
                placeholder="Password"
                name="password"
                v-model="password"
                autofocus
              >
              </v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <ValidationProvider
            name="Confirm Password"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="tw-pt-4 mb-2" :class="classes">
              <v-text-field
                hide-details
                :append-icon="valuee ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (valuee = !valuee)"
                :type="valuee ? 'text' : 'password'"
                solo
                placeholder="Confirm Password"
                name="password"
                v-model="confirmPassword"
              >
              </v-text-field>
              <span>{{ errors[0] }}</span>
            </div>
          </ValidationProvider>

          <base-button
            class="mt-4"
            width="100%"
            :loading="loading"
            button-text="New Password"
            type="submit"
          ></base-button>
        </form>
      </v-col>
    </v-col>

    <modal
      :dialog="dialog"
      title="THANK YOU"
      description="Password Created"
      additional-text="You have successful created your password. Close this dialog box to continue to login"
      bottomText="Back To Login"
      link="Login"
      @close="toggleModal"
    />
  </div>
</template>

<script>
import BaseButton from "../components/reuseables/BaseButton";
import check from "../assets/check_one.svg";
import Modal from "../components/reuseables/Modal";
import {resetManagerNewPassword} from "@/services/api/AuthApiService";
export default {
  name: "StaffNewPassword",
  components: { Modal, BaseButton },
  data() {
    return {
      title: "Voila! Now you can reset your password",
      description: "Here you go, as promised.",
      value: false,
      valuee: false,
      loading: false,
      confirmValue: false,
      dialog: false,
      check,
      password: "",
      confirmPassword: "",
    };
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
    formIsValid() {
      return this.password !== "" && this.confirmPassword !== "";
    },
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.$router.push({ name: "Login" });
    },
    async changePassword() {
      let data = {
        password: this.password,
      };
      this.loading = true;
      if (this.password !== this.confirmPassword) {
        this.loading = false;
        this.password = "";
        this.confirmPassword = "";
        return this.$displaySnackbar({
          message: "Password Do Not Match",
          success: false,
        });
      } else {
        await resetManagerNewPassword(data,this.code)
          .then((res) => {
            this.loading = false;
            this.$displaySnackbar({
              message: res.data.details,
              success: true,
            });
            this.password = "";
            this.confirmPassword = "";
            this.dialog = true;
          })
          .catch((error) => {
            console.log(error.response);
            this.$displaySnackbar({
              message: error.response.data.details[0],
              success: false,
            });
            this.loading = false;
          }).finally(() => this.loading  = false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-family: "Inter", sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #004aad;
}
.input {
  width: 48%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.agree {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.desc {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: rgba(117, 115, 115, 0.5);
}

.left-side {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-image: url("../assets/sign-upbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}
.left-side-body {
  display: flex;
  width: 100%;
  background: rgba(0, 74, 173, 0.51);
  flex-direction: column;
  padding: 5rem 7rem;
  justify-content: center;
}

.right-side {
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 0 !important;
  background: white;
}

.right-side-body {
  display: flex;
  // background: red;
  width: 100%;
  flex-direction: column;
  padding: 7rem;
  justify-content: center;
  // align-items: center;
  @media screen and (max-width: 1440px) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  @media screen and (max-width: 1366px) and (min-width: 1025px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 1.5rem;
  }
}

@media screen and (min-width: 1024px) {
  .right-side-body > img {
    display: none;
  }

  .right-side-body .welcome {
    display: none;
  }
  .right-side-body .admin {
    display: none;
  }
}

.right-side-body .welcome {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: var(--padiGrey);
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0;
}

.right-side-body .admin {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.05em;
  color: var(--padiGrey);
  margin-left: 0;
}

.title {
  font-family: "Inter", serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 26px;
  color: #004aad;
}
.input {
  width: 48%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
.agree {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.7);
}
.have-account {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.buddy {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-top: 4rem;
  margin-left: 0.8rem;
}
.welcome {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35.2px;
  letter-spacing: 0.02em;
  color: #ffffff;
  text-shadow: 0 4px 40px rgba(0, 0, 0, 0.25);
  margin-left: 0.8rem;

  @media screen and (max-width: 1024px) {
    margin-top: 35px;
  }
}
.admin {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.05em;
  color: #ffffff;
  margin-left: 0.8rem;
}

.signup-header {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 64px;
  color: #004aad;
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
}

.terms {
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: var(--padiGrey);
}

.already {
  font-family: "Inter", serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: var(--padiGrey) !important;
}
</style>
